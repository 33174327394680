// import React from 'react';

export const filterSearchString = (recordValue, searchString) =>
  searchString?.toString().toLowerCase().includes(String(recordValue).toLowerCase());

export const filterTransactionStatus = (transactions, value) =>
  transactions?.filter((transaction) => transaction.status === value);

export const moneyFormat = (number = 0, minimumFractionDigits = 2, maximumFractionDigits = 2, currency = 'USD') => {
  // Create our number formatter.
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
  });

  return formattedCurrency.format(number); /* $2,500.00 */
};

export const numberFormat = (number) => {
  const formattedNumber = new Intl.NumberFormat('en-US').format(number);
  return formattedNumber;
};

export const abbreviateNumber = (number) => {
  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

export const DataPlaceHolder = (data) => {
  if (data) {
    return data;
  } else {
    return '-';
  }
};

// formatter and parser input number
export const formatterNumber = (val) => {
  if (!val) return 0;
  // return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(?=\d{0,2}$)/g, '.');
  return `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parserNumber = (val) => {
  if (!val) return 0;
  // return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2);
  return val.replace(/\$\s?|(,*)/g, '');
};

export const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};
