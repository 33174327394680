import React from 'react';
import { Steps } from 'antd';

import './Products.less';

const { Step } = Steps;

const CreateProductSteps = ({ stepNumber }) => {
  return (
    <Steps current={stepNumber} direction="vertical" size="small">
      <Step title="Choose product type" />
      <Step title="Configure product" />
      <Step title="Assign Entities" />
      <Step title="Preview and confirm" />
    </Steps>
  );
};

export default CreateProductSteps;
