import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Products from 'pages/Products';
import SelectProductType from 'pages/Products/SelectProductType';
import ConfigureProduct from 'pages/Products/ConfigureProduct';

import history from 'helpers/history';

const RouterComp = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={SelectProductType} exact />
      <Route path="/products/:productType" component={Products} exact />
      <Route path="/products" component={Products} exact />
      <Route path="/selectProductType" component={SelectProductType} exact />
      <Route path="/configureProduct/:productType" component={ConfigureProduct} exact />
    </Switch>
  </Router>
);

export default RouterComp;
