export const data = {
  data: [
    {
      id: '123899',
      name: 'Silver',
      color: 'white',
      card_type: 'master',
    },
    {
      id: '124512',
      name: 'Bembo Card',
      color: 'white',
      card_type: 'master',
    },
    {
      id: '125258',
      name: 'Platinum',
      color: 'grey',
      card_type: 'visa',
    },
    {
      id: '123897',
      name: 'Johny Bravo',
      color: 'white',
      card_type: 'master',
    },
    {
      id: '124513',
      name: 'Gold',
      color: 'white',
      card_type: 'master',
    },
    {
      id: '125257',
      name: 'John Doe',
      color: 'grey',
      card_type: 'visa',
    },
    {
      id: '125251',
      name: 'Debit Card',
      color: 'grey',
      card_type: 'master',
    },
    {
      id: '125250',
      name: 'Eric Shaw',
      color: 'grey',
      card_type: 'visa',
    },
    {
      id: '125247',
      name: 'Deposits',
      color: 'grey',
      card_type: 'visa',
    },
    {
      id: '123815',
      name: 'James Conor',
      color: 'white',
      card_type: 'master',
    },
    {
      id: '124523',
      name: 'Foo Bar',
      color: 'white',
      card_type: 'master',
    },
  ],
  total: 3,
  current_page: 1,
};
