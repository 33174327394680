import React, { useState } from 'react';
import { Row, Col, Button, Card, Spin, Pagination, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { filter } from 'lodash';

import LayoutWarpper from 'components/Layout';
import { data } from './data';

import './Products.less';

const { Option } = Select;
const { Search } = Input;

const Products = (props) => {
  const initialData = data.data;
  const [productType, setProductType] = useState(props.match.params.productType || 'card');
  const [products, setProducts] = useState(initialData);

  const totalProducts = data?.total || 0;
  const currentPage = data?.current_page || 0;

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleSearchProductChange = (value) => {
    setProductType(value);
  };

  const onSearch = (value) => {
    if (value) {
      setProducts(
        filter(initialData, function (o) {
          return o.name.startsWith(value);
        })
      );
    } else {
      setProducts(initialData);
    }
  };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Products</h1>
      <Spin spinning={false}>
        <div className="searchBar">
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Search placeholder="Search product" onSearch={onSearch} allowClear />
            </Col>
            <Col span={16}>
              <div style={{ float: 'right' }}>
                <Select
                  placeholder="Search product"
                  style={{ width: '220px', marginRight: '20px' }}
                  onChange={handleSearchProductChange}
                  defaultValue={productType}
                >
                  <Option value="card">Cards</Option>
                  <Option value="account">Accounts</Option>
                  <Option value="wallet">Wallets</Option>
                  <Option value="card_account">Card & Account</Option>
                </Select>
                <Button type="primary" size="large">
                  <Link to={`/configureProduct/${productType}`}>
                    <PlusOutlined /> Create a product
                  </Link>
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {products?.length > 0 ? (
          <>
            <Row>
              <Col span={24}>
                <Card>
                  <Row gutter={[16, 16]}>
                    <Col span={productType === 'wallet' || productType === 'account' ? 4 : 6}>
                      <Link to={`/configureProduct/${productType}`}>
                        <div className="newProductBox">
                          <PlusCircleOutlined />
                        </div>
                      </Link>
                    </Col>
                    {products?.map((product) => (
                      <Col
                        span={productType === 'wallet' || productType === 'account' ? 4 : 6}
                        key={`product-${product?.id}`}
                      >
                        <div className="product">
                          {productType === 'card' || productType === 'card_account' ? (
                            <div className="typePreview">
                              <div className={`box ${product?.color}BG`}>
                                <div className="typeLogo">
                                  <img
                                    src={`/images/cards/${product?.card_type}_card_logo.svg`}
                                    alt={`${product?.color} card`}
                                  />
                                </div>
                                <div className="logo">
                                  <img src="/images/cards/default_card_logo.svg" alt="card logo" />
                                </div>
                                <div className="typeText">
                                  <img
                                    src={`/images/cards/debitText_${
                                      product?.color === 'white' || product?.color === 'grey' ? 'black' : 'white'
                                    }.svg`}
                                    alt="debit"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="detail">
                            {productType === 'account' || productType === 'wallet' ? (
                              <div className="accountIcon">
                                <img src={'/images/accountProductIcon.svg'} alt="product" />
                              </div>
                            ) : null}
                            <label>Product Name</label>
                            <p>{product?.name}</p>
                            <label>Product ID</label>
                            <p>{product?.id}</p>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <Row style={{ width: '100%' }} gutter={[16, 16]}>
            <Col span={24} className="productMainPage">
              <div className="centered">
                <img src="images/productIcon.svg" alt="Products" />
                <p>No product has been created yet, your products will show up here once you create some۔</p>
                <Button type="primary" size="large">
                  <Link to="selectProductType">Create a product</Link>
                </Button>
              </div>
            </Col>
          </Row>
        )}
        {totalProducts > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              total={totalProducts}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </Spin>
    </LayoutWarpper>
  );
};

export default Products;
