import React from 'react';

import Router from 'routes';

import './App.less';

function App() {
  return (
    <div className="App">
        <Router />
    </div>
  );
}

export default App;
