import React from 'react';
import { Layout } from 'antd';

// //Components
// import TopBar from './TopBar';
// import SideBar from './Sidebar';

import './Layout.less';

const LayoutWrapper = ({ children }) => {
  const {
    //  Header,
    Content,
    //  Sider
     } = Layout;

  return (
    <>
      <Layout id="MainLayout" key="mainLayout">
        {/* <Header className="header">
          <TopBar></TopBar>
        </Header> */}
        <Content id="contentLayout" key="mainContent">
          <Layout className="site-layout-background" key="contentLayout" style={{ padding: '12px' }}>
            {/* <Sider id="SideBarLayout" className="site-layout-background" width={275}>
              <SideBar />
            </Sider> */}
            <Content
              style={{
                padding: '0 24px',
                minHeight: '85vh',
              }}
              key="childrenContent"
            >
              {children}
            </Content>
          </Layout>
        </Content>
      </Layout>
    </>
  );
};

export default LayoutWrapper;
