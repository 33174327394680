import React from 'react';
import { Row, Col, Radio, Button } from 'antd';
import { Link } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';

import LayoutWarpper from '@Components/Layout';
import CreateProductSteps from './CreateProductSteps';

import './Products.less';

const CreateProduct = () => {
  const [productType, setProductType] = React.useState('card');

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setProductType(e.target.value);
  };

  return (
    <LayoutWarpper>
      <h1 className="page-title">Products - Creation</h1>
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col span={24}>
          <div className="backBtn">
            <Link to={`/products`} style={{ display: 'flex', alignContent: 'center' }}>
              <IoArrowBackCircle size={22} style={{ marginRight: '13px' }} /> <span>Back</span>
            </Link>
          </div>
          <Row gutter={[16, 8]} style={{ marginTop: '40px' }}>
            <Col span={6}>
              <CreateProductSteps stepNumber={0} />
            </Col>
            <Col span={18}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <p>Select the product type you want to create.</p>
                  <Radio.Group onChange={onChange} value={productType}>
                    <Row gutter={[16, 8]}>
                      <Col span={12}>
                        <div className="productType">
                          <div className="icon">
                            <img src="images/cardOption.svg" alt="Card" />
                          </div>
                          <div className="option">
                            <span>Card</span>
                            <Radio value="card" />
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="productType">
                          <div className="icon">
                            <img src="images/accountOption.svg" alt="Account" />
                          </div>
                          <div className="option">
                            <span>Account</span>
                            <Radio value="account" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                      <Col span={12}>
                        <div className="productType">
                          <div className="icon">
                            <img src="images/walletOption.svg" alt="Wallet" />
                          </div>
                          <div className="option">
                            <span>Wallet</span>
                            <Radio value="wallet" />
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="productType">
                          <div className="icon">
                            <img src="images/cardAccountOption.svg" alt="Card & Account" />
                          </div>
                          <div className="option">
                            <span>Card & Account</span>
                            <Radio value="card_account" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col>
              </Row>
              <Row gutter={[16, 8]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Button type="secondary" size="large">
                    <Link to="products">Cancel</Link>
                  </Button>
                  <Button type="primary" size="large" style={{ marginLeft: '20px' }}>
                    <Link to={`/configureProduct/${productType}`}>Continue</Link>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutWarpper>
  );
};

export default CreateProduct;
